html {
  scroll-behavior: smooth;
  scroll-padding-top: 12rem;
}

.App {
  text-align: center;
  min-height: 100vh;
/*  overflow-x: hidden;*/
  position: relative;
}

.background-image {
  background-image: url('./head_logo.svg'); /* Adjust path as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
/*  background-position: center;*/
  background-repeat: no-repeat;
/*  background-size: cover;*/
  background-size: contain; /* or try '100% 100%' to stretch the image */
  background-position: center center; /* Adjust as needed */
  z-index: -1;
/*  transform: translate(-50%, -20%);*/


}

section {
  color: black;
  position: relative;
  z-index: 22;
  background-color: rgba(0, 0, 0, 0.5); /* Example semi-transparent background */
}
.home-section {
  background-color: #fff; /* Semi-transparent */
  height: 75vh;
  margin-bottom: 40vh;
}

.about-section {
  background-color: #fff; 
  height: 65vh;
  margin: 30vh auto;
  padding: 2rem; 
  width: 85%; 
/*  max-width: 600px; */
  background-color: #fff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); 
  border-radius: 10px; 
  position: relative; 
  z-index: 2; 
}

.contact-section {
  background-color: #fff; /* Semi-transparent */
   height: 100vh;
   margin-top: 30vh;
}

.pricing-section {
  background-color: #fff; /* Semi-transparent */
   height: 100vh;
   margin-top: 30rem;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .App-background {
    position: relative;
    width: 100%;
    min-height: 80vh;
    margin-top: 0vh;
  }


    .about-section {
    width: 85%; /* Allows the section to take up more width on smaller screens */
    padding: 1rem; /* Adjusts padding for smaller screens */
    margin: 30vh auto; /* Adjusts the vertical margin */
  }


}