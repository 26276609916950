.footer-container {
  background-color: #fff;
  color: #000;
  text-align: center;
  position: relative;
  left: 0;
  bottom: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  padding-left: 2rem;
}

.social-links a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #aaa;
}



@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-container {
/*    padding: 20px 50px;*/
  }

  .social-links {
    gap: 30px;
  }
}

@media (max-width: 768px) {


.footer-container {
  position: relative;
/*  margin-top: 10rem;*/
  left: 0;
  bottom: 0;
  width: 100%;
  /* Make sure the footer is on top of other content */
  z-index: 1000;
}

}