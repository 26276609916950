/* Adjustments for About.css to fit within the .about-section of app.css, with a focus on mobile readability */

.about-container {
  max-width: 750px; /* Ensures the content does not stretch too wide on larger screens */
  margin: 0 auto; /* Centers the content */
  padding: 1rem; /* Adds padding for better text readability, consistent with the section's padding */
  color: #333; /* Ensures text color contrasts well with the section background */
}

.about-container h1 {
  margin-bottom: 1.5rem; /* Provides space between the title and the first paragraph */
  color: inherit; /* Keeps text color consistent */
}

.about-container p {
  text-align: justify; /* Improves readability */
  margin-bottom: 1rem; /* Spaces out paragraphs */
  color: inherit; /* Ensures consistent text color */
}

/* Responsive adjustments for better mobile readability */
@media (max-width: 768px) {
  .about-container {
    padding: 1rem 0.5rem; /* Reduces side padding on smaller screens for more text space */
  }

  .about-container h1 {
    font-size: 1.25rem; /* Slightly reduces the font size of the heading for more space */
  }

  .about-container p {
    font-size: 0.9rem; /* Reduces the paragraph font size for better fitting */
    line-height: 1.4; /* Adjusts line height for improved readability on small screens */
  }
}
