/* Navbar.css */
.navbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0vh;
  z-index: 2000;
}



.logo-and-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.logo {
  font-size: 20px;
  font-weight: bold;
}

/* Navbar.css */
.logo img {
  height: auto; /* Maintain aspect ratio */
  width: auto; /* You can set either width or height */
  max-height: 60px; /* Adjust based on your navbar height */
  max-width: 100%; /* Ensures the logo will not overflow the navbar */
  margin-right: 20px; 
  min-height: 3rem;

}


.menu-icon {
  display: none;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 30px;
  background-color: #000;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.top.cross {
  transform: rotate(45deg) translate(5px, 5px);
}

.bottom.cross {
  transform: rotate(-45deg) translate(7px, -6px);
}

.middle.show {
  opacity: 0;
}

.desktop-links, .navbar-links {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.desktop-links a, .navbar-links a {
  color: #000;
  text-decoration: none;
  padding: 0.5rem;
}

.desktop-links {
  display: none;
}

@media (min-width: 768px) {
  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .desktop-links {
    display: flex;
  }

  .menu-icon, .navbar-links {
    display: none;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    margin-top: 1rem;
    margin-right: 1.5rem;
    padding-bottom: 1rem;
  }

  .desktop-links {
    display: none;
  }

  .navbar-links {
    flex-direction: column;
    align-items: center;
    overflow: hidden;
/*    margin-top: 4rem;*/
  }  
  .navbar-links a{
/*    padding: 0.5rem;*/
  }

  .logo {
  font-size: 20px;
  font-weight: bold;
  margin-left: 1.5rem;
/*  margin-top: 1rem;*/
  }

  .navbar-container {
    padding: 0.1rem;
    padding-bottom: 1rem;
    position: sticky;
/*    margin-bottom: -5rem;*/
/*      flex: 1;*/

  }

}
