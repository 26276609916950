/* General styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
  margin: 2rem auto; /* Ensures the form is centered and adds margin around it */
  padding: 2rem; /* Adjust padding to fit your design, be cautious with large values */
}

.contact-form label {
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensures inputs and textarea take the full width of the form */
}

.contact-form button {
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 105%; /* Button takes full width of the form for consistency */
}

.contact-form button:hover {
  background-color: #45a049;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .contact-form {
    padding: 4rem; 
  }

.contact-form button {

  width: 108%;

  }

}

.honeypot {
  display: none !important;
}
