.video-background-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire container */
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ddd;;
  text-align: center;
}

.overlay-text h1 {
  font-size: 3rem; /* Increase font size for desktop */
  margin-bottom: 1rem; /* Add some space between the h1 and paragraph */
}

.overlay-text p {
  font-size: 1.5rem; /* Increase font size for desktop */
}

.animate-text {
  animation: fadeIn 5s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Ensure responsiveness on smaller devices */
@media (max-width: 768px) {
  .video-background {
    height: 100vh;
    width: auto;
  }
  
  .overlay-text h1 {
    font-size: 24px; /* Smaller font size for mobile */
  }
  
  .overlay-text p {
    font-size: 18px; /* Smaller font size for mobile */
  }
}
